@use 'sass:map' as map;

/* Class and animation when drag and dropping on file on top of an element */
@keyframes shake {
    0% {
        transform: translate(1px, 1px) rotate(0deg);
    }

    10% {
        transform: translate(-1px, -2px) rotate(-1deg);
    }

    20% {
        transform: translate(-3px, 0px) rotate(1deg);
    }

    30% {
        transform: translate(3px, 2px) rotate(0deg);
    }

    40% {
        transform: translate(1px, -1px) rotate(1deg);
    }

    50% {
        transform: translate(-1px, 2px) rotate(-1deg);
    }

    60% {
        transform: translate(-3px, 1px) rotate(0deg);
    }

    70% {
        transform: translate(3px, 1px) rotate(-1deg);
    }

    80% {
        transform: translate(-1px, -1px) rotate(1deg);
    }

    90% {
        transform: translate(1px, 2px) rotate(0deg);
    }

    100% {
        transform: translate(1px, -2px) rotate(-1deg);
    }
}

@keyframes rotate180 {
    from {
        transform: rotate(0);
    }
    to {
        transform: rotate(180deg);
    }
}

@function define-rotate(
    $duration: 225ms,
    $timing-function: cubic-bezier(0.4, 0.0, 0.2, 1),
    $delay: 0,
) {
    @return (
        duration: $duration,
        timing-function: $timing-function,
        delay: $delay,
    );
}

@mixin animation-rotate($rotate-class, $map) {
    transition-property: transform;
    transition-duration: map.get($map, 'duration');
    transition-timing-function: map.get($map, 'timing-function');
    transition-delay: map.get($map, 'delay');
    transform: rotate(0);
    &.#{class} {
        transform: rotate(180deg);
    }
}
