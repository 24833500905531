@use 'sass:map' as map;
@use '@angular/material' as mat;

@mixin color($theme) {
    @include mat.icon-color($theme);
}

@mixin typography($theme) {
    @include mat.icon-typography($theme);
}

@mixin density($theme) {
    @include mat.icon-density($theme);
}

@mixin theme($theme) {
    @if mat.theme-has($theme, color) {
        @include color($theme);
    }
    @if mat.theme-has($theme, typography) {
        @include typography($theme);
    }
    @if mat.theme-has($theme, density) {
        @include density($theme);
    }

    .ng-fa-icon.animation-rotate {
        svg {
            transition-property: transform;
            transition-duration: 225ms;
            transition-timing-function: cubic-bezier(0.4, 0.0, 0.2, 1);
            transform: rotate(0);
        }
        &.rotate svg {
            transform: rotate(180deg);
        }
    }
}
