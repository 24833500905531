// @use "sass:color" as color;
@use 'sass:map' as map;
@use '@angular/material' as mat;
@import './../theme/palette';
@import './../abstracts/mixins';

@mixin color($theme) {
    // Get the color config from the theme
    @include mat.expansion-color($theme);

    $grey-theme: mat.m2-define-light-theme((
        color: (
            primary: mat.m2-define-palette($md-grey, 500),
            accent: mat.m2-define-palette($md-grey, 500),
        ),
    ));

    .mat-expansion-panel {
        border: 1px solid mat.get-theme-color($grey-theme, primary, 500);
        &:focus {
            border: 2px solid mat.get-theme-color($theme, primary, 500);
        }

        .mat-expansion-panel-header * {
            &, &::after {
                color: mat.get-theme-color($theme, primary, 500);
            }
        }
    }
}

@mixin typography($theme) {
    // Get the typography config from the theme
    @include mat.expansion-typography($theme);

    .mat-mdc-icon-expansion fa-icon svg {
        width: 20px;
        height: 20px;
    }

    .mat-expansion-panel-header-title {
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        /* number of lines to show */
        line-clamp: 2;
        -webkit-box-orient: vertical;
    }
}

@mixin density($theme) {
    @include mat.expansion-density($theme);
}

@mixin theme($theme) {
    @if mat.theme-has($theme, color) {
        @include color($theme);
    }
    @if mat.theme-has($theme, typography) {
        @include typography($theme);
    }
    @if mat.theme-has($theme, density) {
        @include density($theme);
    }
}
