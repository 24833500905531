@use 'sass:map' as map;
@use 'sass:math' as math;
@use '@angular/material' as mat;
@import './../theme/palette';
@import './../abstracts/mixins';

@mixin color($theme) {
    @include mat.table-color($theme);

    .mat-mdc-table, .cdk-table {
        .mat-mdc-header-row, .cdk-header-row {
            background-color: #f0f4f6;
            .mat-mdc-header-cell, .cdk-header-cell {
                color: mat.get-theme-color($theme, primary, 500);
            }
        }

        td {
            border: none;
        }

        @include private-expanded-row-color('expanded-row', $theme, primary);
    }

    div, mat-toolbar, mat-pagination, footer {
        .way-table--footer {
            background-color: white;
        }
    }
}

@mixin typography($theme) {
    @include mat.table-typography($theme);
}

@mixin density($theme) {
    @include mat.table-density($theme);
}

@mixin theme($theme) {
    @if mat.theme-has($theme, color) {
        @include color($theme);
    }
    @if mat.theme-has($theme, typography) {
        @include typography($theme);
    }
    @if mat.theme-has($theme, density) {
        @include density($theme);
    }

    .mat-sort-header-content {
        text-align: left;
    }

    .mat-mdc-table {
        scroll-behavior: smooth;
        @media (prefers-reduced-motion) {
            scroll-behavior: auto;
        }
    }
}

@function define-alternate(
    $palette,
    $odd,
    $even,
    $expandable: false,
    $hover-diff: 100,
    $expanded-class: 'expanded-row',
    $class: '',
    $expanded-hue: 0,
    $selectable: false,
    $selected-palette: $md-primary,
    $selected-hue: 500
) {
    @return (
        palette: $palette,
        odd: $odd,
        even: $even,
        expandable: $expandable,
        hover-diff: $hover-diff,
        expanded-class: $expanded-class,
        class: $class,
        expanded-hue: $expanded-hue,
        selectable: $selectable,
        selected-palette: $selected-palette,
        selected-hue: $selected-hue,
    );
}

@mixin alternate-row-color($map) {
    $light-theme: mat.m2-define-light-theme((
        color: (
            primary: mat.m2-define-palette(map.get($map, 'palette'), 500),
            accent: mat.m2-define-palette(map.get($map, 'palette'), 500),
        ),
    ));
    $class-name: '';
    @if (map.get($map, 'class') != '') {
        $class-name: '.#{map.get($map, 'class')}';
    }

    @if map.get($map, 'expanded-class') {
        $selected-theme: mat.m2-define-light-theme((
            color: (
                primary: mat.m2-define-palette(map.get($map, 'selected-palette'), 500),
                accent: mat.m2-define-palette(map.get($map, 'selected-palette'), 500),
            )
        ));
        @include private-expanded-row-color(map.get($map, 'expanded-class'), $selected-theme, primary, map.get($map, 'selected-hue'));
    }

    .mat-mdc-row#{$class-name}:not(.#{map.get($map, 'expanded-class')}), .cdk-row#{$class-name}:not(.#{map.get($map, 'expanded-class')}) {
        @include private-get-row-color($light-theme, map.get($map, 'even'));
        @if map.get($map, 'expandable') or map.get($map, 'selectable') {
            &:hover {
                @include private-get-row-color($light-theme, map.get($map, 'even') + map.get($map, 'hover-diff'));
            }
        }

        @if (map.get($map, 'expandable') or map.get($map, 'selectable')) {
            &:nth-child(4n+1) {
                @include private-get-row-color($light-theme, map.get($map, 'odd'));
                &:hover {
                    @include private-get-row-color($light-theme, map.get($map, 'odd') + map.get($map, 'hover-diff'));
                }
            }
            &:nth-child(2n) {
                @include private-get-row-color($light-theme, map.get($map, 'expanded-hue'));
            }
        }

        @else {
            &:nth-child(odd) {
                @include private-get-row-color($light-theme, map.get($map, 'odd'));
                @if map.get($map, 'selectable') {
                    &:hover {
                        @include private-get-row-color($light-theme, map.get($map, 'odd') + map.get($map, 'hover-diff'));
                    }
                }
            }
        }
    }
}

@mixin private-get-row-color($theme, $hue, $log: false) {
    $color-config: mat.m2-get-color-config($theme);
    $palette: map.get($color-config, primary);
    @if not map.has-key($palette, $hue) {
        @if $log {
            @warn 'Hue ' + $hue + ' not existing in palette, increasing to the next one';
        }
        $hue: (math.ceil($hue / 100)) * 100;
    }
    @if ($log) {
        @debug 'Get background color ' + $hue;
        @debug 'Get background color ' + $hue + '-contrast';
    }
    $background-color: mat.get-theme-color($theme, primary, $hue);
    $foreground-color: mat.get-theme-color($theme, primary, $hue + '-contrast');

    background-color: $background-color;

    .mat-mdc-cell, .cdk-cell {
        color: $foreground-color;
    }
}

@mixin private-expanded-row-color($class, $theme, $color-range, $hue: 500, $log: false) {
    $hover-hue: $hue + 100;
    // Set the class
    .mat-mdc-row, .cdk-row {
        &.#{$class} {
            @if $log { @debug 'Get color ' + $color-range + ' / ' + $hue; }
            $background-color: mat.get-theme-color($theme, $color-range, $hue);
            @if (type-of($value: $background-color) != color) {
                @warn 'Cannot get theme color for ' + $theme + ' at hue ' + $hue;
            } @else {
                @if $log { @debug '$background-color: ' + $background-color; }
                background-color: $background-color;
            }
            .mat-mdc-cell, .cdk-cell {
                @if $log { @debug 'Get color ' + $color-range + ' / ' + $hue + '-contrast'; }
                $color: mat.get-theme-color($theme, $color-range, $hue + '-contrast');
                @if (type-of($value: $color) != color) {
                    @warn 'Cannot get theme color for ' + $theme + ' at hue ' + $hue + '-contrast';
                } @else {
                    @if $log { @debug '$color: ' + $color; }
                    color: $color;
                }
            }
            &:hover {
                @if $log { @debug 'Get color ' + $color-range + ' / ' + $hover-hue; }
                $background-color-hover: mat.get-theme-color($theme, $color-range, $hover-hue);
                @if (type-of($value: $background-color-hover) != color) {
                    @warn 'Cannot get theme color for ' + $theme + ' at hue ' + $hue;
                } @else {
                    @if $log { @debug '$background-color-hover: ' + $background-color-hover; }
                    background-color: $background-color-hover;
                }
                .mat-mdc-cell, .cdk-cell {
                    @if $log { @debug 'Get color ' + $color-range + ' / ' + $hover-hue + '-contrast'; }
                    $color-hover: mat.get-theme-color($theme, $color-range, $hover-hue + '-contrast');
                    @if (type-of($value: $color-hover) != color) {
                        @warn 'Cannot get theme color for ' + $theme + ' at hue ' + $hue;
                    } @else {
                        @if $log { @debug '$background-color-hover: ' + $background-color-hover; }
                        color: $color-hover
                    }
                }
            }
        }
    }
}

.way-table--main-container {
    display: flex;
    flex-direction: column;
    padding: 0 16px 16px 16px;
    overflow: auto;

    @media screen and (max-width: 768px) {
        padding: 0;
    }

    .table-container {
        @include mat.elevation(2);
        display: flex;
        flex-direction: column;
        max-height: calc(100vh - 176px);
        overflow-y: auto;
        border-radius: .5rem .5rem 0 0;

        @media screen and (max-width: 768px) {
            max-height: calc(100vh - 163px);
        }

        table {
            width: 100%;
            margin-bottom: 0px;
        }
    }

    footer, .way-table--footer {
        @include mat.elevation(2);
        width: 100%;
        border-radius: 0 0 .5rem .5rem;
        position: sticky;

        @media screen and (max-width: 768px) {
            border-radius: 0;
        }
    }

    .table-loader {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 1rem;
        padding-bottom: 1rem;
        overflow: hidden;
    }

}
