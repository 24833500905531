@use 'sass:map' as map;
@use '@angular/material' as mat;
@use './../theme/theme' as theme;
@import './../abstracts/mixins';

@mixin color($theme) {
    @include mat.menu-color($theme);

    .mat-mdc-menu-item {
        &.warn * {
            $warn: mat.get-theme-color($theme, warn, 500);
            color: $warn;
        }
    }
}

@mixin typography($theme) {
    @include mat.menu-typography($theme);

    .mat-mdc-menu-trigger {
        font-family: theme.$font-family;
    }
}

@mixin density($theme) {
    $density-scale: mat.get-theme-density($theme);
    @include mat.menu-density($theme);

    .mat-mdc-menu-item {
        [class$="text"] {
            mat-icon, fa-icon {
                margin-right: calc(1em + ($density-scale * 2px));
            }
        }
    }
}

@mixin theme($theme) {
    @if mat.theme-has($theme, color) {
        @include color($theme);
    }
    @if mat.theme-has($theme, typography) {
        @include typography($theme);
    }
    @if mat.theme-has($theme, density) {
        @include density($theme);
    }

    .help-menu {
        max-width: 100% !important;
    }

    .mat-mdc-menu-item {
        [class$="text"] {
            display: flex;
            align-items: center;
        }
    }
}
