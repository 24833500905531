@use '@angular/material' as mat;
@import '../theme/palette';
@import '../abstracts/mixins';

$white: #ffffff;
$black: #000000;
$table-header: #f0f4f6;
$table-line: #f7f9fa;
$box-shadow: 0 2px 4px rgb(0 0 0 / 50%);
$header-height: 50px;

/* Palette color */
$way-primary: mat.m2-define-palette($md-primary);
$way-accent: mat.m2-define-palette($md-accent, 500, A100, A400);
$way-warn: mat.m2-define-palette($md-warn);
$way-green: mat.m2-define-palette($md-green);
$way-yellow: mat.m2-define-palette($md-yellow);
$way-pink: mat.m2-define-palette($md-pink);
$way-purple: mat.m2-define-palette($md-purple);
$way-orange: mat.m2-define-palette($md-orange);
$way-grey: mat.m2-define-palette($md-grey);

/* Translated colors from palette */
$primary: get-color-from-palette($way-primary);
$light-primary: get-color-from-palette($way-primary, 200);
$accent: get-color-from-palette($way-accent, 500);
$warn: get-color-from-palette($way-warn);
$green: get-color-from-palette($way-green);
$yellow: get-color-from-palette($way-yellow);
$pink: get-color-from-palette($way-pink);
$orange: get-color-from-palette($way-orange, 500);
$grey: get-color-from-palette($way-grey, 500);
$dark-grey: get-color-from-palette($way-grey, 900);
$light-grey: get-color-from-palette($way-grey, 200);
$light-light-grey: get-color-from-palette($way-grey, 75);
$very-light-grey: get-color-from-palette($way-grey, 50);

$monospace-typography: mat.m2-define-typography-config(
    $font-family: 'Consolas' 'Courier New' monospace,
    $body-1: mat.m2-define-typography-level($font-size: 12px),
    $body-2: mat.m2-define-typography-level($font-size: 12px),
);

$way-theme-mono-primary: mat.m2-define-light-theme((
    color: ( primary: $way-primary, accent: $way-primary, warn: $way-primary )
));
$way-theme-mono-accent: mat.m2-define-light-theme((
    color: ( primary: $way-accent, accent: $way-accent, warn: $way-accent )
));
$way-theme-mono-warn: mat.m2-define-light-theme((
    color: ( primary: $way-warn, accent: $way-warn, warn: $way-warn )
));
$way-theme-mono-green: mat.m2-define-light-theme((
    color: ( primary: $way-green, accent: $way-green, warn: $way-green )
));
$way-theme-mono-pink: mat.m2-define-light-theme((
    color: ( primary: $way-pink, accent: $way-pink, warn: $way-pink )
));
$way-theme-mono-yellow: mat.m2-define-light-theme((
    color: ( primary: $way-yellow, accent: $way-yellow, warn: $way-yellow )
));
$way-theme-mono-orange: mat.m2-define-light-theme((
    color: ( primary: $way-orange, accent: $way-orange, warn: $way-orange )
));
$way-theme-mono-grey: mat.m2-define-light-theme((
    color: ( primary: $way-grey, accent: $way-grey, warn: $way-grey )
));
$way-theme-mono-purple: mat.m2-define-light-theme((
    color: ( primary: $way-purple, accent: $way-purple, warn: $way-purple )
));

@mixin location-list() {
    display: grid;
    grid-auto-flow: row;
    gap: .25em;

    &>span {
        &:not(:first-child)::before {
            font-family: "Material Icons", sans-serif;
            content: "\e5da";
        }

        $left-gap-unit: .5em;

        &:nth-child(2) {
            margin-left: $left-gap-unit;
        }

        &:nth-child(3) {
            margin-left: 1 + $left-gap-unit;
        }
    }
}
