// @use "sass:color" as color;
@use 'sass:map' as map;
@use '@angular/material' as mat;
@import './../theme/palette';
@import './../abstracts/mixins';

@mixin color($theme) {
    @include mat.snack-bar-color($theme);

    .mat-mdc-snack-bar-container.toast-notification {
        & > * {
            background-color: white;
        }

        .mat-mdc-snack-bar-label, .mat-mdc-snack-bar-actions {
            color: black;
        }

        &.green-snackbar {
            @include bar-color(get-color-from-palette($md-green, 500))
        }

        &.red-snackbar {
            @include bar-color(get-color-from-palette($md-warn, 500))
        }

        &.orange-snackbar {
            @include bar-color(get-color-from-palette($md-yellow, 500))
        }

        &.blue-snackbar {
            @include bar-color(get-color-from-palette($md-primary, 500))
        }
    }
}

@mixin typography($theme) {
    @include mat.snack-bar-typography($theme);

    .mat-mdc-snack-bar-container {

        .mat-mdc-snack-bar-label {
            font: mat.get-theme-typography($theme, 'subtitle-2');
        }

        &.action-icon .mat-mdc-snack-bar-action {
            font-family: 'Material Icons';
        }
    }
}

@mixin density($theme) {
    @include mat.snack-bar-density($theme);
}

@mixin theme($theme) {
    @if mat.theme-has($theme, color) {
        @include color($theme);
    }
    @if mat.theme-has($theme, typography) {
        @include typography($theme);
    }
    @if mat.theme-has($theme, density) {
        @include density($theme);
    }
}

@mixin bar-color($color) {
    &>* {
        $status-bar-width: .35em;

        &::before {
            content: '';
            width: $status-bar-width;
            height: 100%;
            background-color: $color;
            border-radius: 4px 0 0 4px;
            position: absolute;
        }

        &>* {
            margin-left: $status-bar-width;
        }
    }
}
