// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;

@import './palette';

// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

$font-family: Inter, Roboto, "Helvetica Neue", sans-serif;

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$way-front-primary: mat.m2-define-palette($md-primary);
$way-front-accent: mat.m2-define-palette($md-accent, 500);

// The warn palette is optional (defaults to red).
$way-front-warn: mat.m2-define-palette($md-warn);

// @debug(mat.m2-define-typography-config());

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$way-front-theme: mat.m2-define-light-theme((
  color: (
    primary: $way-front-primary,
    accent: $way-front-accent,
    warn: $way-front-warn,
  ),
  typography: mat.m2-define-typography-config(
    $font-family: $font-family,
    $subtitle-2: mat.m2-define-typography-level(
        $font-family: $font-family,
        $font-size: 14px,
        $font-weight: 700,
    ),
    $body-1: mat.m2-define-typography-level(
        $font-family: $font-family,
        $font-size: 12px,
    ),
    $body-2: mat.m2-define-typography-level(
        $font-family: $font-family,
        $font-size: 12px,
    ),
    $button: mat.m2-define-typography-level(
        $font-family: $font-family,
        $font-size: 12px,
        $line-height: 24px,
    ),
  ),
  density: -2,
));
// $headline-1
// $headline-2
// $headline-3
// $headline-4
// $headline-5
// $headline-6
// $subtitle-1
// $subtitle-2
// $body-1
// $body-2
// $caption
// $overline
