@use 'sass:color' as color;
@use '@angular/material' as mat;
@use 'src/scss/index' as way;

$backdrop-height: 50;

.tutorial-backdrop {
    background-color: transparentize($color: #000000, $amount: .6);
}

@keyframes highlight-ripple {
    0% {
        opacity: 0;
        transform: scale(0);
    }

    33% {
        opacity: 1;
        transform: scale(1);
    }

    66% {
        opacity: 0;
        transform: scale(1.5);
    }

    100% {
        opacity: 0;
    }
}

@mixin action($color: color.adjust(mat.get-theme-color(way.$way-front-theme, primary, 500), $alpha: -0.5)) {
    position: relative;

    &::before {
        content: "";
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        display: block;
        position: absolute;
        background-color: $color;
        border-radius: .5rem;
        transform-origin: 50% 50%;
        animation-name: highlight-ripple;
        animation-timing-function: cubic-bezier(0.18, 0.89, 0.32, 1.28);
        animation-duration: 1.5s;
        animation-iteration-count: infinite;
    }
}

.tutorial-action {
    @include action();
}
