@use 'sass:map' as map;
@use 'sass:color' as color;
@use '@angular/material' as mat;
@import './../abstracts/mixins';

@mixin color($theme) {
    // Get the color config from the theme
    @include mat.button-toggle-color($theme);

    [data-color='primary'] {
        @include mat-button-toggle-colors($theme, primary, 500, 50);
    }

    [data-color='accent'] {
        @include mat-button-toggle-colors($theme, accent, 500, 50);
    }
}

@mixin typography($theme) {
    // Get the typography config from the theme
    @include mat.button-toggle-typography($theme);
}

@mixin density($theme) {
    @include mat.button-toggle-density($theme);
}

@mixin theme($theme) {
    @if mat.theme-has($theme, color) {
        @include color($theme);
    }
    @if mat.theme-has($theme, typography) {
        @include typography($theme);
    }
    @if mat.theme-has($theme, density) {
        @include density($theme);
    }
}

@mixin mat-button-toggle-colors($theme, $color-range, $light-level, $dark-level) {
    $font-dark: mat.get-theme-color($theme, $color-range, $dark-level + '-contrast');
    $font-light: mat.get-theme-color($theme, $color-range, $light-level + '-contrast');

    .mat-button-toggle-disabled {
        $opacity: .87;
        cursor: not-allowed;
        color: opacify($color: $font-dark, $amount: $opacity);

        &.inverse {
            color: opacify($color: $font-light, $amount: $opacity);
        }

        button {
            opacity: .6;
        }
    }

    .mat-button-toggle-checked {
        $foreground: mat.get-theme-color($theme, $color-range, $light-level + '-contrast');

        background-color: mat.get-theme-color($theme, $color-range, $light-level);
        color: $foreground;

        .mat-pseudo-checkbox {
            --mat-minimal-pseudo-checkbox-selected-checkmark-color: $foreground;
        }

        &.inverse {
            $foreground: mat.get-theme-color($theme, $color-range, $dark-level + '-contrast');

            background-color: mat.get-theme-color($theme, $color-range, $dark-level);
            color: $foreground;

            .mat-pseudo-checkbox {
                --mat-minimal-pseudo-checkbox-selected-checkmark-color: $foreground;
            }
        }
    }
}
