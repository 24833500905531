@use 'sass:map' as map;
@use '@angular/material' as mat;
@import './../abstracts/mixins';

@mixin color($theme) {
    @include mat.tabs-color($theme);

    .mat-mdc-tab-header {
        border-bottom: 1px solid mat.get-theme-color($theme, primary, 500);
    }
}

@mixin typography($theme) {
    @include mat.tabs-typography($theme);
}

@mixin density($theme) {
    @include mat.tabs-density($theme);
}

@mixin theme($theme) {
    @if mat.theme-has($theme, color) {
        @include color($theme);
    }
    @if mat.theme-has($theme, typography) {
        @include typography($theme);
    }
    @if mat.theme-has($theme, density) {
        @include density($theme);
    }
}
