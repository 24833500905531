@use 'sass:map' as map;
@use '@angular/material' as mat;

@mixin color($theme) {
    // Get the color config from the theme
    @include mat.card-color($theme);
}

@mixin typography($theme) {
    // Get the typography config from the theme
    @include mat.card-typography($theme);
}

@mixin density($theme) {
    @include mat.card-density($theme);
}

@mixin theme($theme) {
    @if mat.theme-has($theme, color) {
        @include color($theme);
    }
    @if mat.theme-has($theme, typography) {
        @include typography($theme);
    }
    @if mat.theme-has($theme, density) {
        @include density($theme);
    }

    .mdc-card.chart {
        @include mat.elevation(2);
        padding: 1em;
    }

    .mat-mdc-card-title {
        align-items: center;
    }

    .mat-mdc-card-actions {
        justify-content: end;
        gap: 1em;
    }
}
