@use 'sass:map' as map;
@use '@angular/material' as mat;

@mixin color($theme) {
    @include mat.toolbar-color($theme);
}

@mixin typography($theme) {
    @include mat.toolbar-typography($theme);
}

@mixin density($theme) {
    @include mat.toolbar-density($theme);
}

@mixin theme($theme) {
    @if mat.theme-has($theme, color) {
        @include color($theme);
    }
    @if mat.theme-has($theme, typography) {
        @include typography($theme);
    }
    @if mat.theme-has($theme, density) {
        @include density($theme);
    }

    mat-toolbar {
        .spacer {
            flex: 1 1 auto;
        }
    }
}
