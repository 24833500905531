@use '@angular/material' as mat;
@use 'sass:map' as map;
@use './../abstracts/mixins' as m;
@import './../abstracts/variables';
@import './../theme/palette';

@mixin color($theme) {
    @include mat.chips-color($theme);

    .mat-mdc-chip {
        @each $class in ('orange', 'maintenance') {
            &.#{$class} {
                @include define-color-with-palette($md-orange);
            }
        }

        @each $class in ('warn', 'red', 'quarantine', 'stop', 'rejected', 'deleted', 'expirated', 'expired') {
            &.#{$class} {
                @include define-color-with-palette($md-warn);
            }
        }

        @each $class in ('green', 'available', 'done', 'activated', 'start', 'inService', 'in_service', 'loaded', 'validated') {
            &.#{$class} {
                @include define-color-with-palette($md-green);
            }
        }

        @each $class in ('empty') {
            &.#{$class} {
                background-color: $white;
                color: $green;
                border-color: $green;
                border: 2px solid;
            }
        }

        @each $class in ('pink', 'allocated') {
            &.#{$class} {
                @include define-color-with-palette($md-pink);
            }
        }

        @each $class in ('white') {
            &.#{$class} {
                @include define-color-with-palette($md-white);
            }
        }

        @each $class in ('lighter-grey') {
            &.#{$class} {
                @include define-color-with-palette($md-grey, 50);
            }
        }

        @each $class in ('light-grey') {
            &.#{$class} {
                @include define-color-with-palette($md-grey, 200);
            }
        }

        @each $class in ('grey', 'pending') {
            &.#{$class} {
                @include define-color-with-palette($md-grey, 500);
            }
        }

        @each $class in ('dark') {
            &.#{$class} {
                @include define-color-with-palette($md-grey, 900);
            }
        }

        @each $class in ('yellow', 'on_hold', 'otm') {
            &.#{$class} {
                @include define-color-with-palette($md-yellow);
            }
        }

        @each $class in ('accent', 'no_gps', 'operator') {
            &.#{$class} {
                @include define-color-with-palette($md-accent);
            }
        }

        @each $class in ('primary', 'in_progress', 'isWorking', 'is_working', 'inUse', 'inuse', 'manager', 'administrator') {
            &.#{$class} {
                @include define-color-with-palette($md-primary)
            }
        }

        @each $class in ('light-primary') {
            &.#{$class} {
                @include define-color-with-palette($md-primary, 200)
            }
        }

    }
}

@mixin typography($theme) {
    // Get the typography config from the theme
    @include mat.chips-typography($theme);
}

@mixin density($theme) {
    @include mat.chips-density($theme);

    .mat-mdc-chip-grid > div[role="presentation"] {
        align-items: center;
    }

    .mat-mdc-chip-input {
        flex: 1 1;
    }
}

@mixin theme($theme) {
    @if mat.theme-has($theme, color) {
        @include color($theme);
    }
    @if mat.theme-has($theme, typography) {
        @include typography($theme);
    }
    @if mat.theme-has($theme, density) {
        @include density($theme);
    }

    .mat-mdc-chip {
        height: 24px;
        // * {
        //     text-transform: uppercase;
        // }
    }

    .chips-content {
        max-width: 125px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .mat-mdc-chip.clickable .mat-mdc-chip-action {
        cursor: pointer;
    }
}

@mixin define-color-with-palette($palette, $level: 500) {
    $light-theme: mat.m2-define-light-theme((
        color: (
            primary: mat.m2-define-palette($palette, $level),
            accent: mat.m2-define-palette($palette, $level),
        ),
    ));
    background-color: mat.get-theme-color($light-theme, primary, $level);
    [class$="-label"] {
        $level-contrast: $level + '-contrast';
        color: mat.get-theme-color($light-theme, primary, $level-contrast);
    }
}

#v14 {

    /* TODO(mdc-migration): The following rule targets internal classes of chips that may no longer apply for the MDC version. */
    .planning--table-cell-chips .mat-chip-list-wrapper {
        flex-direction: column !important;
        margin: 0.3em;
    }
}
