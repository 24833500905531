@use 'sass:map' as map;
@use '@angular/material' as mat;

@mixin color($theme) {
    @include mat.radio-color($theme);
}

@mixin typography($theme) {
    @include mat.radio-typography($theme);
}

@mixin density($theme) {
    @include mat.radio-density($theme);

    .mat-mdc-radio-button:not(:last-child) {
        margin-right: 1em;
    }
}

@mixin theme($theme) {
    @if mat.theme-has($theme, color) {
        @include color($theme);
    }
    @if mat.theme-has($theme, typography) {
        @include typography($theme);
    }
    @if mat.theme-has($theme, density) {
        @include density($theme);
    }
}
