/* You can add global styles to this file, and also import other style files */

@use '@angular/material' as mat;
@use '@ng-matero/extensions' as mtx;
@use './scss/components/index' as component;

@import './scss/theme/theme';

@include mat.core-theme($way-front-theme);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($way-front-theme);
.wcag {
    &-focus-indicators {
        @include mat.strong-focus-indicators((
            border-style: dotted,
            border-width: 2px,
        ));
        $way-front-strong-focus-palette: mat.m2-define-palette($md-accent, 500);
        $way-front-strong-focus-theme: mat.m2-define-light-theme((
            color: ( primary: $way-front-strong-focus-palette, accent: $way-front-strong-focus-palette )
        ));
        @include mat.strong-focus-indicators-theme($way-front-strong-focus-theme);
    }
}

// Increased specificity
.body {
    @include component.button-theme($way-front-theme);
    @include component.button-toggle-theme($way-front-theme);
    @include component.card-theme($way-front-theme);
    @include component.checkbox-theme($way-front-theme);
    @include component.chip-theme(mat.m2-define-light-theme((
        color: mat.m2-get-color-config($way-front-theme),
        typography: mat.m2-define-typography-config(
            $font-family: $font-family,
            $body-2: mat.m2-define-typography-level($font-size: 10px),
            $button: mat.m2-define-typography-level($font-size: 10px),
        ),
    )));
    @include component.dialog-theme($way-front-theme);
    @include component.expansion-theme($way-front-theme);
    @include component.form-field-theme($way-front-theme);
    @include component.icon-theme($way-front-theme);
    @include component.input-theme($way-front-theme);
    @include component.menu-theme(mat.m2-define-light-theme((
        color: mat.m2-get-color-config($way-front-theme),
        typography: mat.m2-define-typography-config(
            $font-family: $font-family,
            $body-1: mat.m2-define-typography-level($font-size: 14px),
            $body-2: mat.m2-define-typography-level($font-size: 14px),
            $button: mat.m2-define-typography-level($font-size: 14px),
        ),
        density: -2,
    )));
    @include component.radio-theme($way-front-theme);
    @include component.select-theme($way-front-theme);
    @include component.snackbar-theme($way-front-theme);
    @include component.tab-theme($way-front-theme);
    @include component.table-theme(mat.m2-define-light-theme((
        color: mat.m2-get-color-config($way-front-theme),
        typography: mat.m2-define-typography-config(
            $font-family: $font-family,
            $subtitle-2: mat.m2-define-typography-level(
                $font-size: 13px,
                $font-weight: 700,
            ),
            $body-1: mat.m2-define-typography-level($font-size: 11px),
            $body-2: mat.m2-define-typography-level($font-size: 11px),
            $button: mat.m2-define-typography-level(
                $font-size: 11px,
                $line-height: 20px,
            ),
        ),
    )));
    @include component.toolbar-theme($way-front-theme);
    @include component.tooltip-theme($way-front-theme);
    // @ng-matero components styles
    @include mtx.all-component-themes($way-front-theme);
}


/**
* Abstracts
* All the sass helpers are here, variables, mixins, functions...
*/
@import './scss/abstracts/variables';
@import './scss/components/overlay';
@import './scss/components/esri-map';
@import './scss/components/tutorial';
@import './scss/components/mtx';

html,
body {
    height: 100%;
}

body {
    margin: 0;
    font-family: $font-family;
    background-color: $white;
}

::-webkit-scrollbar {
    width: 12px;
    height: 12px;
}

::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
}

::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: $light-grey;
}

.way-component--header {
    display: flex;
    flex-direction: row;
    min-height: 55px;
    justify-content: space-between;
    align-items: center;
    margin: 5px 15px;

    .way-component--header-title {
        font-size: 15px;
        font-weight: bold;
        color: $dark-grey;
    }

    .way-component--header-buttons {
        display: flex;

        button {
            align-items: center;
            margin-left: 10px;
        }
    }
}

.way-filter-container {
    @include mat.elevation(4);
    z-index: 500;
}

.way-component--filterable-container {
    display: flex;
    flex-direction: column;
}

@font-face {
    font-family: 'Beafont';
    src: url('./assets/font/Baefont_normal-Regular_V1.ttf');
}
#body.chaos * {
    :not([class*="fas"]):not(mat-icon) {
        font-family: 'Beafont', Roboto;
    }
}
