/* Remove blue fram around map pin point when focused */
#map_graphics_layer path {
    stroke: none !important;
}

.esriPopup {
    font-family: Inter, "Roboto", sans-serif !important;
}

.esriPopupWrapper {
    margin: 5px;
    box-shadow: none !important;
}

/* Map items cursor on hover */
#assetClusterFeatureLayer_layer circle,
text {
    cursor: pointer;
    font-family: Inter, "Roboto", sans-serif !important;
}

#beaconClusterFeatureLayer_layer circle,
text {
    cursor: pointer;
    font-family: Inter, "Roboto", sans-serif !important;
}

#anchorClusterFeatureLayer_layer circle,
text {
    cursor: pointer;
    font-family: Inter, "Roboto", sans-serif !important;
}

#historyAssetClusterFeatureLayer_layer circle,
text {
    cursor: pointer;
    font-family: Inter, "Roboto", sans-serif !important;
}

#assetGraphicsLayer_layer path {
    cursor: pointer;
}

#beaconGraphicsLayer_layer path {
    cursor: pointer;
}

#anchorGraphicsLayer_layer path {
    cursor: pointer;
}

#historyGraphicsLayer_layer path {
    cursor: pointer;
}

#map_graphics_layer path {
    cursor: pointer;
}

.no-data-message {
    margin-top: 8px;
    color: $warn;
}
