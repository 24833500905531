.overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 50px;
    left: 0;
    background-color: $dark-grey;
    z-index: 999;
}

.spinner {
    $topOffset: 50px + 25px;
    position: absolute;
    top: calc(50% - #{$topOffset});
    left: calc(50% - 50px);
}
