/* For use in src/lib/core/theming/_palette.scss */
$md-primary: (50 : #e0e4eb,
    100 : #b3bcce,
    200 : #8090ad,
    300 : #4d638c,
    400 : #264174,
    500 : #00205b,
    600 : #001c53,
    700 : #001849,
    800 : #001340,
    900 : #000b2f,
    A100 : #687bff,
    A200 : #354eff,
    A400 : #0221ff,
    A700 : #001de7,
    contrast: (50 : #000000,
        100 : #000000,
        200 : #ffffff,
        300 : #ffffff,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #ffffff,
        A400 : #ffffff,
        A700 : #ffffff,
    ));

$md-accent: (50 : #e0f5f8,
    100 : #b3e7ee,
    200 : #80d7e3,
    300 : #4dc6d8,
    400 : #26bacf,
    500 : #00aec7,
    600 : #00a7c1,
    700 : #009dba,
    800 : #0094b3,
    900 : #0084a6,
    A100 : #d0f4ff,
    A200 : #9de8ff,
    A400 : #6adbff,
    A700 : #51d5ff,
    contrast: (50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    ));

$md-warn: (50 : #fce0e6,
    100 : #f7b3bf,
    200 : #f28095,
    300 : #ec4d6b,
    400 : #e8264b,
    500 : #e4002b,
    600 : #e10026,
    700 : #dd0020,
    800 : #d9001a,
    900 : #d10010,
    A100 : #fff9f9,
    A200 : #ffc6c8,
    A400 : #ff9397,
    A700 : #ff7a7e,
    contrast: (50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #ffffff,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    ));

$md-green: (
    50 : #e1f1eb,
    100 : #b5dbce,
    200 : #84c3ad,
    300 : #52ab8c,
    400 : #2d9974,
    500 : #08875a,
    600 : #077f53,
    700 : #067449,
    800 : #046a40,
    900 : #02572f,
    A100 : #89ffbe,
    A200 : #56ffa2,
    A400 : #23ff86,
    A700 : #09ff78,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$md-pink: (
    50 : #fbe9f3,
    100 : #f6c8e1,
    200 : #f0a3ce,
    300 : #ea7eba,
    400 : #e662ab,
    500 : #e1469c,
    600 : #dd3f94,
    700 : #d9378a,
    800 : #d52f80,
    900 : #cd206e,
    A100 : #ffffff,
    A200 : #ffd2e5,
    A400 : #ff9fc8,
    A700 : #ff85b9,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$md-yellow: (
    50 : #fff9e6,
    100 : #feefc2,
    200 : #fde599,
    300 : #fcda70,
    400 : #fcd251,
    500 : #fbc932,
    600 : #fac52d,
    700 : #fabd26,
    800 : #f9b71f,
    900 : #f8ab13,
    A100 : #ffffff,
    A200 : #fffbf3,
    A400 : #ffe8c0,
    A700 : #ffdfa7,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #000000,
        700 : #000000,
        800 : #000000,
        900 : #000000,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$md-orange: (
    50 : #fff2e6,
    100 : #ffdec2,
    200 : #ffc999,
    300 : #ffb370,
    400 : #ffa251,
    500 : #ff9233,
    600 : #ff8a2d,
    700 : #ff7f26,
    800 : #ff751f,
    900 : #ff6313,
    A100 : #ffffff,
    A200 : #fffbfa,
    A400 : #ffd8c7,
    A700 : #ffc6ad,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #000000,
        700 : #000000,
        800 : #000000,
        900 : #000000,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$md-grey: (50 : #f0f0f0,
    75: #d7dbe3,
    100 : #d9d9d9,
    200 : #c0c0c0,
    300 : #a6a6a6,
    400 : #939393,
    500 : #808080,
    600 : #787878,
    700 : #6d6d6d,
    800 : #636363,
    900 : #505050,
    A100 : #f8c0c0,
    A200 : #f39292,
    A400 : #ff5353,
    A700 : #ff3a3a,
    contrast: (50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #ffffff,
    ));

$md-purple: (
    50 : #f6e9f4,
    100 : #e9c8e4,
    200 : #dba3d3,
    300 : #cd7ec1,
    400 : #c262b3,
    500 : #b746a6,
    600 : #b03f9e,
    700 : #a73795,
    800 : #9f2f8b,
    900 : #90207b,
    A100 : #ffccf5,
    A200 : #ff99ea,
    A400 : #ff66e0,
    A700 : #ff4dda,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$md-white: (
    100: #ffffff,
    300: #ffffff,
    500: #ffffff,
    700: #ffffff,
    900: #ffffff,
    contrast: (
        100: #000000,
        300: #000000,
        500: #000000,
        700: #000000,
        900: #000000,
    )
);
