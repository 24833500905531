@use 'sass:map' as map;
@use '@angular/material' as mat;

@mixin color($theme) {
    @include mat.form-field-color($theme);
}

@mixin typography($theme) {
    @include mat.form-field-typography($theme);
}

@mixin density($theme) {
    $density-scale: mat.get-theme-density($theme);
    @include mat.form-field-density($theme);

    // Set fixed width for filter panels
    .filter .mat-mdc-form-field {
        width: 220px + ($density-scale * 4px);
    }

    .mat-mdc-form-field-input-control {
        $form-field-space: -($density-scale * 3px);
        position: relative;
        top: $form-field-space;
        &.mat-mdc-form-field-textarea-control {
            margin-bottom: $form-field-space;
        }
        &.mat-mdc-chip-input {
            top: 0;
        }
    }
}

@mixin theme($theme) {
    @if mat.theme-has($theme, color) {
        @include color($theme);
    }
    @if mat.theme-has($theme, typography) {
        @include typography($theme);
    }
    @if mat.theme-has($theme, density) {
        @include density($theme);
    }

    .mat-mdc-form-field.never-hint {
        .mat-mdc-form-field-subscript-wrapper {
            display: none;
        }
    }

    .mat-mdc-form-field-hint, .mat-mdc-form-field-error {
        display: grid;
        grid-auto-flow: column;
        justify-content: start;
        align-items: center;
        gap: 0.5em;
        overflow: hidden;

        & > .hide-overflow {
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
        }
    }


}
