@use 'sass:map' as map;
@use '@angular/material' as mat;

@mixin color($theme) {
    @include mat.tooltip-color($theme);
}

@mixin typography($theme) {
    @include mat.tooltip-typography($theme);

    .mat-tooltip-multiline * {
        white-space: pre-line;
    }
}

@mixin density($theme) {
    @include mat.tooltip-density($theme);
}

@mixin theme($theme) {
    @if mat.theme-has($theme, color) {
        @include color($theme);
    }
    @if mat.theme-has($theme, typography) {
        @include typography($theme);
    }
    @if mat.theme-has($theme, density) {
        @include density($theme);
    }
}
