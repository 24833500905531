@use '@angular/material' as mat;
@use 'sass:map' as map;
@import './../abstracts/mixins';

@mixin color($theme) {
    // Get the color config from the theme
    @include mat.dialog-color($theme);

    .mat-mdc-dialog-title {
        background-color: mat.get-theme-color($theme, primary, 500);
        * {
            color: mat.get-theme-color($theme, primary, 500-contrast);
        }
        &.warn {
            background-color: mat.get-theme-color($theme, warn, 500);
            * {
                color: mat.get-theme-color($theme, warn, 500-contrast);
            }
        }
    }
}

@mixin typography($theme) {
    // Get the typography config from the theme
    @include mat.dialog-typography($theme);
}

@mixin density($theme) {
    @include mat.dialog-density($theme);

    .mat-mdc-dialog-container .mat-mdc-dialog-title+.mat-mdc-dialog-content {
        padding-top: 1em;
    }
}

@mixin theme($theme) {
    @if mat.theme-has($theme, color) {
        @include color($theme);
    }
    @if mat.theme-has($theme, typography) {
        @include typography($theme);
    }
    @if mat.theme-has($theme, density) {
        @include density($theme);
    }

    .mat-mdc-dialog-title {
        display: grid;
        grid-template-columns: 0 1fr auto;
        align-items: center;
        padding-bottom: 0;

        &s:not(:first-child) {
            margin-right: 2em;
        }

        &>*:last-child {
            margin-right: 0;
        }
    }
    .mat-mdc-dialog-actions {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(5em, auto));
        justify-content: end;
        gap: .5em;
    }
}
