@use 'sass:map' as map;
@use '@angular/material' as mat;

@mixin color($theme) {
    // Get the color config from the theme
    @include mat.button-color($theme);
}

@mixin typography($theme) {
    // Get the typography config from the theme
    @include mat.button-typography($theme);

    .mat-mdc-icon-button fa-icon svg {
        width: 20px;
        height: 20px;
    }
}

@mixin density($theme) {
    $density-scale: mat.get-theme-density($theme);
    @include mat.button-density($theme);

    .mdc-button {
        mat-icon, fa-icon {
            margin-right: calc(1em + ($density-scale * 2px));
        }
    }
}

@mixin theme($theme) {
    @if mat.theme-has($theme, color) {
        @include color($theme);
    }
    @if mat.theme-has($theme, typography) {
        @include typography($theme);
    }
    @if mat.theme-has($theme, density) {
        @include density($theme);
    }

    .mdc-button__label {
        display: grid;
        grid-auto-flow: column;
        align-items: center;
        gap: 0.5em;
        text-wrap: nowrap;
    }
}
